<template>
    <div class="contents">
        <a class="button" @click="$refs.projectEditModal.open()" style="float: right">Edit</a>
        <div id="top">
            <img :src="project.image+'?nocache='+Date.now()">
            <input type="text" v-model="project.name">
        </div>

        <Modal ref="projectEditModal" mtitle="Edit Project" :cssHide='true'>
            <span class="label">PICTURE</span>
            <picture-upload ref="uploadPicture" :profilePicture="project.image"
                            :uploadUrl="'/api/v2/projects/'+id+'/picture'"/>
            <br>
            <span class="label">NAME</span>
            <input type="text" class="input" v-model="project.name">
            <a class="button delete" @click="deleteApp">Delete</a>
            <a class="button" @click="editProfile" style="float: right">Save</a>
        </Modal>

        <br><br>
        <a class="button" @click="$refs.createAppModal.open()" style="float: right">Create App</a>
        <h2>Apps</h2><br><br>
        <Modal mtitle="Create App" ref="createAppModal">
            <span class="label">TYPE</span>
            <select class="input" v-model="newAppProjectType">
                <option value="OAUTH2">OAuth2</option>
            </select>
            <span class="label">NAME</span>
            <input autofocus type="text" v-model="newAppName" placeholder="Name" class="input">
            <span class="label">URL</span>
            <input type="text" v-model="newAppUrl" placeholder="URL" class="input">
            <a class="button" style="float: right" @click="createApp">Create</a>
        </Modal>
        <div>
            <router-link class="list-entry-with-icon clickable" v-for="app in apps" :key="app.id"
                         :to="'/developers/projects/apps/'+app.type.toLowerCase()+'/'+app.id">
                <img :src="app.picture"/>
                <div class="list-entry-with-icon-info">
                    <h1>{{ app.name }}</h1>
                    <p>Type: {{ app.type }}</p>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import Modal from "../../components/Modal";

import PictureUpload from '../../components/PictureUpload.vue';
import helper from '../../helper';

export default {
    data: () => ({
        id: 0,
        project: {},
        apps: [],
        newAppProjectType: "OAUTH2",
        newAppName: "",
        newAppUrl: "https://"
    }),
    mounted() {
        this.id = this.$route.params.id
        this.load()
        this.$refs.uploadPicture.$on("uploaded", () => {
            this.project.image = ""
            this.load()
        })
    },
    components: {
        Modal,
        PictureUpload
    },
    methods: {
        load() {
            this.apiClient.get("/api/v2/projects/" + this.id)
                .then(res => res.json())
                .then(res => {
                    if (res.success)
                        this.project = res
                })

            this.apiClient.get("/api/v2/projects/" + this.id + "/apps")
                .then(res => res.json())
                .then(res => {
                    if (res.success)
                        this.apps = res.data
                })
        },
        editProfile() {
            this.apiClient.put("/api/v2/projects/" + this.id, {
                name: this.project.name
            }).then(this.apiClient.errorCheck)
                .then(() => {
                    helper.showSnackBar("Saved")
                    this.$refs.projectEditModal.close()
                })
        },
        deleteApp() {
            this.apiClient.delete("/api/v2/projects/" + this.id)
                .then(this.apiClient.errorCheck)
                .then(() => {
                    helper.showSnackBar("Deleted")
                    this.$router.push("/developers/projects")
                })
        },
        createApp() {
            this.apiClient.post("/api/v2/projects/" + this.id + "/app", {
                name: this.newAppName,
                url: this.newAppUrl,
                type: this.newAppProjectType
            })
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        this.load()
                        this.$refs.createAppModal.close()
                        this.newAppName = ""
                        this.newAppUrl = ""
                    } else {
                        helper.showSnackBar("Error: " + res.message, "#f54242")
                    }
                })
        }
    }
}
</script>

<style lang="scss" scoped>
#top {
    margin-top: 30px;

    img {
        display: inline-block;
        vertical-align: middle;
        width: 50px;
        height: 50px;
        padding: 4px;
        background: #00000011;
        border-radius: 6px;
    }

    input {
        color: #545454;
        display: inline-block;
        vertical-align: middle;
        margin-left: 20px;
        border: none;
        font-size: 32px;
        font-weight: 600;
        max-width: calc(100% - 100px);
    }
}

h2 {
    color: #878787;
    font-size: 27px;
}
</style>